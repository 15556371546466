body {
    background-color: rgb(255, 255, 255);
    color: rgb(29, 25, 25);
    padding-left: 1pt;
    padding-right: 4px;
    font-family: Arial;
    text-align: center;
    box-shadow: #282c34;
    box-sizing: border-box;
}

table.some_style TableCell {
    background-color: #0f0;
}

table.some_style TableCell:hover {
    background-color: #06c;
}

textarea {
    backgroundColor: '#213919';
    border: 1px solid #314929;
    color: #afac87;
    fontSize: 1em;
    height: calc(50% - 20px);
    overflowY: auto;
    padding: 5px;
    width: calc(100% - 12px);
}
defaultValue{
    color: rgba(0, 0, 0, 0.87)
}
title{
    flex-grow: 1;
    display: none;
    position: center;
    text-align: center;
}

div.search:hover{
   background-color:whitesmoke;
}
div.inputRoot{
    width: auto;
}
root{
flex-grow: 1;
}
.container{
    margin: 36px auto;
    width: 50%;
    max-width: 800px;
    position: relative;
}
.heading{
    font-size:30px;
    color: #444;
    text-align: center;
}
.search-label{
    position: relative;
    width: 10px;
}
.search-input{
    width: 130px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    background-color: white;
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 7px 37px 7px 15px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
}
.search-input:hover{
    width:30%;
}
.search-txt{
    /*visibility: hidden;*/
    position: absolute;
    top: -1px;
    font-size: 18px;
    color: #555;
    right: 9px;
}
